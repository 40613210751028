.anmeldung {
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 6rem;
  position: relative;
  background-image: linear-gradient(
      135deg,
      rgba(186, 186, 186, 0.03) 0%,
      rgba(186, 186, 186, 0.03) 10%,
      rgba(133, 133, 133, 0.03) 10%,
      rgba(133, 133, 133, 0.03) 14%,
      rgba(38, 38, 38, 0.03) 14%,
      rgba(38, 38, 38, 0.03) 17%,
      rgba(93, 93, 93, 0.03) 17%,
      rgba(93, 93, 93, 0.03) 25%,
      rgba(80, 80, 80, 0.03) 25%,
      rgba(80, 80, 80, 0.03) 45%,
      rgba(239, 239, 239, 0.03) 45%,
      rgba(239, 239, 239, 0.03) 100%
    ),
    linear-gradient(
      135deg,
      rgba(236, 236, 236, 0.03) 0%,
      rgba(236, 236, 236, 0.03) 47%,
      rgba(182, 182, 182, 0.03) 47%,
      rgba(182, 182, 182, 0.03) 63%,
      rgba(223, 223, 223, 0.03) 63%,
      rgba(223, 223, 223, 0.03) 81%,
      rgba(86, 86, 86, 0.03) 81%,
      rgba(86, 86, 86, 0.03) 89%,
      rgba(23, 23, 23, 0.03) 89%,
      rgba(23, 23, 23, 0.03) 90%,
      rgba(226, 226, 226, 0.03) 90%,
      rgba(226, 226, 226, 0.03) 100%
    ),
    linear-gradient(
      45deg,
      rgba(52, 52, 52, 0.03) 0%,
      rgba(52, 52, 52, 0.03) 31%,
      rgba(246, 246, 246, 0.03) 31%,
      rgba(246, 246, 246, 0.03) 63%,
      rgba(188, 188, 188, 0.03) 63%,
      rgba(188, 188, 188, 0.03) 71%,
      rgba(15, 15, 15, 0.03) 71%,
      rgba(15, 15, 15, 0.03) 87%,
      rgba(127, 127, 127, 0.03) 87%,
      rgba(127, 127, 127, 0.03) 93%,
      rgba(234, 234, 234, 0.03) 93%,
      rgba(234, 234, 234, 0.03) 100%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);

  .container {
    max-width: 900px;
    margin: auto;
  }

  .header {
    .title {
      font-family: $font-family-1;
      font-size: 2.4rem;
    }
    .img-header {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      padding-bottom: 2rem;
      img {
        height: 160px;
        width: auto;
        max-width: 100%;
        object-fit: scale-down;
      }
    }
  }
  .form-title {
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 0.25rem;
  }
  .info-kasse {
    position: relative;
    background-color: #fff;
    padding: 1.5rem 5%;
    border-radius: 4px;
    border: solid 1px $border-color;
    box-shadow: $main-box-shadow;
    &.freeze {
      pointer-events: none;
      #purchaseBtn {
        display: none;
      }
    }
    .error-top {
      color: $red-color;
      font-size: 1rem;
      transition: $transition;
      opacity: 0;
      &.error {
        opacity: 1;
      }
    }

    .input-container {
      width: 100%;
      transition: $transition;
      margin-bottom: 1.3rem;
      p {
        margin-bottom: 0.4rem;
      }
      input {
        width: 100%;
      }
      .birthday-input {
        max-width: 120px;
      }
      small {
        opacity: 0;
        color: $red-color;
        font-weight: 600;
      }
      &.error {
        input {
          border: 0.5px solid $red-color;
        }
        small {
          opacity: 1;
        }
      }
    }

    .law-container {
      display: flex;
      font-size: 0.8rem;
      max-width: 600px;
      a {
        color: $third-color;
        font-size: 0.8rem;
      }
      .law-text {
        padding-left: 0.5rem;
      }
      small {
        display: block;
        opacity: 0;
        font-size: 1.2rem;
        font-weight: 600;
        transition: all 0.25s ease-out;
      }
      &.error small {
        opacity: 1;
        color: $red-color;
      }
    }
  }
  .wie-erreichbar {
    .checks-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      @media (max-width: 400.98px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .one-check {
          padding-bottom: 1rem;
        }
      }
    }
    .one-check {
      display: flex;
      font-weight: 600;
      .check-text {
        transform: translateY(2px);
        padding-left: 1rem;
      }
    }
  }

  button {
    min-width: 180px;
    max-height: 100%;
    font-size: 1.1rem;
    transition: $transition;
    &.disable {
      opacity: 0;
      pointer-events: none;
    }
  }
  .info-kasse {
    transition: all 0.4s ease-in;
    &.inactive {
      opacity: 0;
      pointer-events: none;
    }
  }
}
