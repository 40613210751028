@import "./../../scss/config";
#BoxMessage,
#MessageInHandler {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -100%);

  transition: all 0.8s ease-in-out;
  cursor: pointer;
  z-index: 10000;
  &.active {
    transform: translate(0%, 0%);
  }
  .content {
    width: 90%;
    max-width: 600px;
    margin: auto;
    font-size: 1.2rem;
    background-color: #fff;
    border-radius: 4px;
    padding: 1rem;
    padding-bottom: 2rem;
    background-color: #fafafa;
    opacity: 0.9;
    color: #333;
    cursor: default;
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: gray;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .btn_container {
    padding-top: 1rem;
    display: none;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 450.98px) {
  #BoxMessage,
  #MessageInHandler {
    .content {
      font-size: 1rem;
    }
  }
}
