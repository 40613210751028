@import "allfiles";
@import "../assets/main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  &:hover {
    text-decoration: none;
    color: $third-color;
  }
}

.test-space {
  min-height: 100vh;
}
ul {
  list-style: none;
}

// .mobile-overflow-wrapper {
//   overflow-x: hidden;
// }
