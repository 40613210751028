.faq-home {
  padding: 3rem 0rem;
  background-color: $secondary-color;
  padding-bottom: 5rem;
  h2 {
    text-align: center;
    padding-bottom: 2rem;
    color: #333;
    font-size: 2.4rem;
    text-transform: uppercase;
  }
  .one-accordion {
    max-width: 900px;
    margin: auto;
    padding-bottom: 2rem;
  }
  .one-accordion-box {
    max-width: 900px;
    margin: auto;
    background-color: #fff;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
    padding: 1rem 12px;
    border-bottom: solid 2px $primary-color;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }

  #open-btn,
  #close-btn {
    &.open {
      display: inline-block;
    }
    &.closed {
      display: none;
    }
  }
  #open-btn {
    transform: translate(1px, 0px);
  }
  #close-btn {
    transform: translate(0px, 0px);
  }
  .title,
  .date {
    color: #333;
    font-weight: 600;
    font-size: 1.3rem;
  }
  .accordion-btn {
    display: inline-block;
    font-size: 1.3rem;
    margin-left: 0.25rem;
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    background-color: rgba(102, 102, 102, 0.35) !important;
    border: 2px solid $primary-color;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
  }
  .accordion-content-container {
    padding: 0rem 12px;
    height: 0;
    overflow: hidden;
    transition: all 0.4s ease-out;

    .sub-title {
      padding-bottom: 0.25rem;
    }
    li {
      margin-left: 0.75rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      i {
        align-self: flex-start;
        color: $primary-color;
        margin-right: 0.75rem;
        font-size: 0.9rem;
        transform: translateY(6px);
      }
    }
    &.active {
      padding: 1rem 12px;
    }
    .sub-title {
      color: $blue-color;
    }
  }

  // .link-extern{

  // }
}
