.theorie-klassen {
  padding-top: 5rem;
  padding-bottom: 4rem;
  .title-line {
    position: relative;
    padding-bottom: 5rem;
    z-index: 100;

    h3 {
      position: relative;
      overflow: hidden;
      text-align: center;
      font-size: 2.7rem;
      padding-top: 5rem;
      font-family: $font-family-1;
      span {
        position: absolute;
        transform: translate(10px, -45px);
        z-index: 100;
      }
      @media (max-width: 575.98px) {
        font-size: 1.7rem;
      }
    }
    h3:before,
    h3:after {
      background-color: $mittel-color;
      content: "";
      display: inline-block;
      height: 16px;
      position: relative;
      //   vertical-align: middle;
      width: 50%;
    }
    h3:before {
      right: 0.5em;
      margin-left: -50%;
    }
    h3:after {
      left: 0.5em;
      margin-right: -20%;
      width: 78%;
    }
    @media (max-width: 575.98px) {
      h3:before {
        right: 0.5em;
        margin-left: -50%;
      }
      h3:after {
        left: 0.5em;
        margin-right: -80%;
        width: 78%;
      }
    }
  }
  .row {
    max-width: 900px;
    margin: auto;
  }

  .one-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
  }
  .img-container {
    background-color: $hell-color;
    opacity: 0.8;
    width: 120px;
    border-radius: 12px;
    cursor: pointer;
    &:hover .content-overlay {
      opacity: 1;
    }
    &:hover .content-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }
    img {
      height: 60px;
      width: 60px;
      object-fit: scale-down;
      transform: translate(30px, 8px);
    }
    .auto-plus {
      transform: translate(35px, 8px);
    }
    p {
      text-align: center;
      font-family: $font-family-1;
      color: $primary-color;
      font-weight: 600;
      font-size: 1.3rem;
    }
  }
  .text-for-box {
    display: none;
  }
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .content-overlay {
    position: absolute;
    height: 100%;
    width: 120px;
    margin: auto;
    border-radius: 12px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  .content-details i {
    color: #fff;
    font-size: 1.5rem;
    transition: all 0.4s ease-in-out 0s;
    &:hover {
      color: $primary-color;
    }
  }
  .fadeIn-top {
    top: 20%;
  }
}
