.second-theme {
  background-image: linear-gradient(
      45deg,
      transparent 0%,
      transparent 55%,
      rgba(64, 64, 64, 0.04) 55%,
      rgba(64, 64, 64, 0.04) 76%,
      transparent 76%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 14%,
      rgba(64, 64, 64, 0.04) 14%,
      rgba(64, 64, 64, 0.04) 41%,
      transparent 41%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 2%,
      rgba(64, 64, 64, 0.04) 2%,
      rgba(64, 64, 64, 0.04) 18%,
      transparent 18%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 61%,
      rgba(64, 64, 64, 0.04) 61%,
      rgba(64, 64, 64, 0.04) 71%,
      transparent 71%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  min-height: 100vh;
  margin-top: -8px;
  .right-col {
    padding-bottom: 5rem;
    .img-container {
      width: 100%;
      overflow: hidden;
      height: 300px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 1.2s ease-in;

        // transform: scale(1.05);
        &.animation-zoom-img {
          transform: scale(2);
        }
      }
    }
    h1 {
      color: $primary-color;
      font-family: $font-family-1;
      font-size: 2.5rem;
    }
    hr {
      width: 80px;
      color: $primary-color;
      height: 3px;
      background-color: $primary-color;
      margin-right: auto;
      border: none;
      margin-top: 0.2rem;
      margin-bottom: 1rem;
    }
    .sub-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: $primary-color;
    }
    ul {
      li {
        padding-bottom: 0.5rem;
      }
      i {
        font-size: 0.5rem;
        transform: translateY(-2px);
        color: $primary-color;
      }
    }
    .now-contact-right {
      padding: 2rem 12px;
      border-radius: 4px;
      border: $gray-color-2 4px dashed;
      font-size: 1.2rem;
      .col-2 {
        margin-right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
          height: 100px;
          width: auto;
          max-width: auto;
          object-fit: scale-down;
        }
      }
      .sub-title-2 {
        font-weight: 600;
      }
    }
  }

  .left-col {
    .content {
      background-color: $primary-color;
      color: #fff;
      padding: 1.2rem 12px;
      border-radius: 4px;
      box-shadow: $main-box-shadow;
      max-width: 500px;
      margin: auto;
      .title {
        font-family: $font-family-1;
        font-size: 1.6rem;
      }

      li {
        padding-bottom: 0.4rem;
        font-size: 1.1rem;
      }
    }
    .now-contact {
      position: -webkit-sticky;
      position: sticky;
      background-color: #fff;
      top: 0px;
      padding: 1.2rem 12px;
      border-radius: 4px;
      border: $gray-color-2 4px dashed;
      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }
  .img-part {
    max-width: 500px;
    margin: auto;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}
