.instagram {
  margin-bottom: 10rem;
  .title {
    color: darken($secondary-color, 20%);
    text-align: center;
    font-size: 2rem;
    padding-bottom: 1rem;
  }
  .img-col {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-container {
    position: relative;
    height: 300px;
    margin: 0rem;
    padding: 0;
    width: 100%;
    cursor: pointer;
    &:hover .content-overlay {
      opacity: 1;
    }
    &:hover .content-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .content-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  .content-details i {
    color: #fff;
    font-size: 1.5rem;
    transition: all 0.4s ease-in-out 0s;
    &:hover {
      color: $primary-color;
    }
  }
  .fadeIn-top {
    top: 20%;
  }

  .img-insta {
    height: 60px !important;
    width: 60px !important;
    object-fit: scale-down;
  }
}
