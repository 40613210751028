.location {
  min-height: 500px;
  .img-col {
    padding: 0 16px;
    margin: 0;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    @media (max-width: 575.98px) {
      min-height: 300px;
    }
    iframe {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      border: none;
      // width: 95%;
      margin-right: auto;
      @media (max-width: 400.98px) {
        margin: auto;
        width: 95%;
      }
    }
  }
  .title {
    font-family: $font-family-1;
    font-size: 2.5rem;
  }
  .content {
    padding: 2rem 12px;
    max-width: 550px;
    margin: auto;
  }

  .anmeldeBtn {
    background-color: $primary-color;
    max-width: 300px;
    border-radius: 12px;
    color: #fff;
    padding: 0.75rem;
    font-size: 1.1rem;
    cursor: pointer;
    text-align: center;
    transition: $transition;
    i {
      font-size: 1.6rem;
      transform: translateY(4px);
    }
    &:hover {
      background-color: darken($primary-color, 6%);
    }
  }
  .aufklapp-container {
    min-height: 120px;
  }
  .aufklapp {
    background-color: $primary-color;
    max-width: 300px;
    border-radius: 12px;
    color: #fff;
    padding: 0.75rem;
    font-size: 1.1rem;
    cursor: pointer;
    img {
      height: 40px;
      width: 40px;
      object-fit: scale-down;
      transform: translateY(2px);
    }
    .first-col {
      display: flex;
      align-items: center;
    }
    .hover-el {
      max-height: 0;
      transition: max-height 0.6s ease-out;
      overflow: hidden;
    }
    &:hover {
      .hover-el {
        max-height: 150px;
        transition: max-height 0.6s ease-in;
      }
    }
  }
  .zeiten {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 1.2rem;
    }
    @media (max-width: 575.98px) {
      flex-direction: column;
      align-items: flex-start;
      .one-zeit {
        padding-bottom: 2rem;
      }
    }
  }
}
