.about-us {
  min-height: 800px;
  background-color: $mittel-color;
  position: relative;
  padding-bottom: 5rem;
  .main-image {
    width: 400px;
    height: auto;
    max-height: 500px;
    object-fit: scale-down;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .content-container {
    max-width: 1200px;
    margin: auto;
    padding: 2rem 16px;
  }
  .title {
    font-size: 2.8rem;
    font-family: $font-family-1;
    color: #fff;
  }
  .one-col {
    position: relative;
  }

  .one-content-left {
    max-width: 350px;
    color: #fff;
  }
  .one-content-right {
    max-width: 350px;
    color: #fff;
    margin-left: auto;
    margin-right: 0;
  }
  .content-top {
    transform: translateY(120px);
  }
  .sub-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .from-top-1 {
    margin-top: 0rem;
  }
  .from-top-2 {
    margin-top: 15rem;
  }
  .one-image-1 {
    width: 200px;
    height: auto;
    // max-height: 500px;
    object-fit: scale-down;
    position: absolute;
    top: 2%;
    right: 10%;
    z-index: 1;
  }
  .one-image-3 {
    width: 200px;
    height: auto;
    // max-height: 500px;
    object-fit: scale-down;
    position: absolute;
    top: -3%;
    left: 8%;
    z-index: 1;
  }
  .one-image-2 {
    width: 200px;
    height: auto;
    // max-height: 500px;
    object-fit: scale-down;
    position: absolute;
    top: -70%;
    right: 14%;
    z-index: 1;
  }
  .one-image-4 {
    width: 200px;
    height: auto;
    // max-height: 500px;
    object-fit: scale-down;
    position: absolute;
    top: -70%;
    left: 13%;
    z-index: 1;
  }
  .btn-transparent {
    font-family: $font-family-1;
    font-size: 1.2rem;
  }
  .one-image-1,
  .one-image-2,
  .one-image-3,
  .one-image-4 {
    cursor: pointer;
    transition: $transition;
    &:hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 991.98px) {
    .main-image,
    .one-image-1,
    .one-image-2,
    .one-image-3,
    .one-image-4 {
      display: none;
    }
    .content-top {
      transform: unset;
    }
    .content-container {
      max-width: 800px;
      margin: auto;
    }
    .from-top-2 {
      margin-top: 4rem;
    }
  }
  @media (max-width: 767.98px) {
    .one-content-left,
    .one-content-right {
      margin: auto;
      padding-bottom: 2.5rem;
    }
    .from-top-1,
    .from-top-2 {
      margin-top: 0rem;
    }
  }
}
