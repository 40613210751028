.testimonials {
  position: relative;
  width: 100%;

  h2 {
    position: absolute;
    color: $gruen-color;
    font-family: $font-family-1;
    left: 45%;

    z-index: 100;
    span {
      display: block;
      font-size: 3.2rem;
    }
    .second {
      transform: translateY(-16px);
    }
    @media (max-width: 991.98px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .right-col {
    background-color: $mittel-color;
    min-height: 650px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .stars-container {
      display: flex;
      align-self: center;

      padding-bottom: 2rem;
      p {
        padding: 0 0.25rem;
      }
      img {
        height: 30px;
        width: 30px;
        object-fit: scale-down;
      }
    }
    @media (max-width: 575.98px) {
      min-height: 750px;
    }

    @media (max-width: 420.98px) {
      min-height: 850px;
    }
  }

  .glider-contain {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    max-width: 1400px;
    padding: 0 16px;
    .glider {
      padding: 0rem 0rem;
    }

    .one-icon {
      background-color: #f2f2f2;
      padding: 1.5rem 12px;
      // cursor: pointer;
      transition: all 0.8s ease-out;
      border: solid 1px $secondary-color;
      margin: 0px 16px;
      border: solid 2px #fff;
      opacity: 0.9;
      border-radius: 4px;
      &:hover {
        border: solid 2px #ffffff;
        -webkit-box-shadow: -1px 0px 45px -9px rgba(201, 201, 201, 0.32);
        box-shadow: -1px 0px 45px -9px rgba(201, 201, 201, 0.32);
      }
      .text-part {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
      }
      .name {
        padding-top: 0.5rem;
        color: $third-color;
      }
    }
    .glider-prev,
    .glider-next {
      font-size: 1.5rem;
      background: #333333;
      color: #ffffff;
      margin-top: 1rem;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 50%;

      .fas {
        margin-left: 1rem;
      }
    }
    .glider-prev {
      transform: translateX(20px);
    }
    .glider-next {
      transform: translateX(-20px);
    }
  }
}
