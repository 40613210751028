.calendar-container {
  position: relative;
  $width: 400px;
  width: $width;
  // margin: auto;
  .termin {
    width: $width;
    border: solid 1px $border-color;
    padding: 0.3rem 0.2rem;
    border-radius: 4px;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    transition: $transition;
    background-color: #fff;
    i {
      margin-left: 0.4rem;
    }
    &:hover {
      color: $primary-color;
    }
    &.error {
      border-color: $red-color;
    }
  }
  .pop-up-calendar {
    position: absolute;
    width: $width;
    background-color: #fff;
    border: solid 1px $border-color;
    margin-top: -30px;
    opacity: 0;
    z-index: 100;
    pointer-events: none;
    font-size: 0.9rem;
    height: 340px;
    transition: all 0.4s ease-in;
    &.active {
      opacity: 1;
      transform: translateY(30px);
      pointer-events: all;
    }
    .month {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 10px;
      text-align: center;
      background-color: #fff;
      color: $primary-color;
    }
    #month {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .weekdays {
      background-color: #fff;
      color: #333;
      padding: 3px 0;
      display: flex;
    }

    .days {
      padding: 5px 0;
      display: flex;
      flex-wrap: wrap;
    }

    .weekdays div,
    .days div {
      text-align: center;
      width: 14.28%;
    }

    .days div {
      padding: 4px 0;
      margin-bottom: 5px;
      transition: all 0.4s;
      font-weight: 600;
      border-radius: 12px;
    }

    .prev_date {
      color: #999;
    }

    .today {
      background-color: lighten($primary-color, 20%);
      color: #fff;
    }

    .days div:hover {
      cursor: pointer;
      background-color: #dfe6e9;
    }

    .prev,
    .next {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.4s;
    }

    .prev:hover,
    .next:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  @media (max-width: 575.98px) {
    $width: 300px;
    .pop-up-calendar,
    .termin {
      width: $width;
    }
  }
  @media (max-width: 350.98px) {
    $width: 260px;
    .pop-up-calendar,
    .termin {
      width: $width;
    }
  }
}
