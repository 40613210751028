.all_kurse {
  .warteliste {
    padding: 3rem 0rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

    background-color: $secondary-color;
    h2 {
      font-size: 2rem;
      font-weight: 600;
      text-align: center !important;
    }
    hr {
      width: 60px;
      color: $primary-color;
      height: 4px;
      background-color: $primary-color;
      margin: auto;
      border: none;
      margin-top: 0.2rem;
      margin-bottom: 2rem;
    }
    p {
      max-width: 750px;
      margin: auto;
      font-size: 1.2rem;
    }
  }
  .row {
    position: unset !important;
  }
}
