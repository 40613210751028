.three_boxes {
  padding-bottom: 8rem;

  .boxes-container {
    display: flex;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    padding: 0 12px;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: -95px;
    @media (max-width: 767.98px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -20px;
      .box {
        margin-bottom: 2rem;
      }
    }
  }
  .box {
    width: 350px;
    max-width: 100%;
    z-index: 200;
    padding: 0rem 0.5rem;
    transition: all 0.75s ease-in;
    opacity: 1;
    &.animation-start {
      transform: translateY(150px);
      opacity: 0;
    }
    .img-container {
      background-color: $gruen-color;
      height: 160px;
      width: 160px;
      border-radius: 50%;
      margin: auto;
    }
    img {
      height: 112px;
      width: 112px;
      object-fit: scale-down;
      transform: translate(25px, 25px);
    }
    .title {
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      padding-bottom: 0.25;
    }
    p {
      color: #333;
      font-size: 1rem;
      text-align: center;
    }
    .full-btn {
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: all 0.25s ease-out;
      min-height: 60px;
      a {
        background-color: $primary-color;
        text-align: center;
        width: 100%;
        font-size: 1.1rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        transition: all 0.25s ease-out;
      }
      &:hover {
        background-color: darken($primary-color, 10%);
        a {
          background-color: darken($primary-color, 10%);
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .three_boxes {
    .title-script {
      font-size: 2.4rem;
    }
  }
}
