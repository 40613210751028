/*FAQ section, where the accordion is kept, including section specific css*/

#faq {
  padding-top: 3rem;
  padding-bottom: 8rem;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  h1 {
    font-size: 2rem;
    text-align: center;
    color: $primary-color;
    padding-bottom: 2rem;
  }
  .add {
    /*Add is the name for the plus icon*/
    display: block;
  }

  .remove {
    /*Remove is the name for the less icon*/
    display: none;
  }

  i {
    font-size: 30px;
  }
  /*Accordion container and each item*/
  .accordion {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .accordion-item {
    background-color: transparent;
    border-bottom: 3px solid $primary-color;

    margin-bottom: 10px;
    padding: 10px;
  }

  /*Styles for the question*/

  .accordion-title {
    display: flex;

    align-items: center;
    justify-content: space-around;

    color: black;
    font-size: 1.1rem;

    font-weight: 600;

    width: 100%;
    padding: 1rem 0;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  .accordion-title:hover {
    color: $primary-color;
  }

  .accordion-title h2 {
    width: 80%;
  }

  /*Styles for the answer*/

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    position: relative;
    background-color: $light-color;
    transition: max-height 1.2s;
    p {
      padding: 20px;
      color: #000000;
      font-size: 1rem;
    }
  }
}
