.dropdown {
  position: relative;
  width: 200px;
  background-color: #fff;
  &.error {
    .dropdown-btn {
      border-color: $red-color;
    }
  }
  .dropdown-btn {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    background: none;
    text-decoration: none;
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 0.3rem 0.2rem;
    color: #333;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
    &.error {
      border-color: $red-color;
    }
    i {
      margin-left: 0.5rem;
      pointer-events: none;
    }
  }
  ul {
    margin-top: 10px;
    position: absolute;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 200px;
    background: $light-color;
    border: 2px solid $border-color;
    left: 0px;
    list-style: none;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: all 0.4s ease;
    font-size: 1.1rem;
    background-color: #fff;
  }
  li {
    width: 100%;
    padding: 0.5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $border-color;
    cursor: pointer;
    &:hover {
      background-color: lighten($primary-color, 30%);
    }
    p {
      padding-left: 1.2rem;
    }
  }
  &.active {
    ul {
      z-index: 100;
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }
  }
}
.dropdown-choice-element {
  li {
    padding-left: 0rem;
    justify-content: center;
  }
}
