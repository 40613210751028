.kontakt {
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 4rem;
  position: relative;
  &:before {
    content: "";
    position: absolute;

    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin-top: -3rem;
    opacity: 0.1;
    background: radial-gradient(
        circle,
        transparent 20%,
        #f8f9fa 20%,
        #f8f9fa 80%,
        transparent 80%,
        transparent
      ),
      radial-gradient(
          circle,
          transparent 20%,
          #f8f9fa 20%,
          #f8f9fa 80%,
          transparent 80%,
          transparent
        )
        10px 10px,
      linear-gradient(#65b700 0.8px, transparent 0.8px) 0 -0.4px,
      linear-gradient(90deg, #65b700 0.8px, #f8f9fa 0.8px) -0.4px 0;
    background-size: 20px 20px, 20px 20px, 10px 10px, 10px 10px;
  }
  .content-container {
    margin: auto;
    margin-bottom: 1rem;
    max-width: 600px;
  }
  .title {
    font-size: 2.2rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    color: $primary-color;
  }
  .desc {
    margin-bottom: 0rem;
    font-size: 1.2rem;
    padding-bottom: 2rem;
  }

  .input-container {
    padding-bottom: 0.8rem;
    p {
      padding-bottom: 0.2rem;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  .law-container {
    display: flex;
    padding: 1rem 0rem;
    font-size: 0.75rem;
    a {
      font-size: 0.75rem;
    }
  }

  textarea {
    height: 200px;
  }
  .btn-container {
    text-align: center;
    button {
      text-transform: uppercase;
    }
  }
  .birthday-input {
    max-width: 120px;
  }
  small {
    display: none;
    color: $red-color;
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.25 ease-out;
    &.error {
      display: block;
    }
  }
}
