.anmeldung2-all {
  padding-top: 3rem;
  padding-bottom: 6rem;
  position: relative;
  background-image: linear-gradient(
      135deg,
      rgba(186, 186, 186, 0.03) 0%,
      rgba(186, 186, 186, 0.03) 10%,
      rgba(133, 133, 133, 0.03) 10%,
      rgba(133, 133, 133, 0.03) 14%,
      rgba(38, 38, 38, 0.03) 14%,
      rgba(38, 38, 38, 0.03) 17%,
      rgba(93, 93, 93, 0.03) 17%,
      rgba(93, 93, 93, 0.03) 25%,
      rgba(80, 80, 80, 0.03) 25%,
      rgba(80, 80, 80, 0.03) 45%,
      rgba(239, 239, 239, 0.03) 45%,
      rgba(239, 239, 239, 0.03) 100%
    ),
    linear-gradient(
      135deg,
      rgba(236, 236, 236, 0.03) 0%,
      rgba(236, 236, 236, 0.03) 47%,
      rgba(182, 182, 182, 0.03) 47%,
      rgba(182, 182, 182, 0.03) 63%,
      rgba(223, 223, 223, 0.03) 63%,
      rgba(223, 223, 223, 0.03) 81%,
      rgba(86, 86, 86, 0.03) 81%,
      rgba(86, 86, 86, 0.03) 89%,
      rgba(23, 23, 23, 0.03) 89%,
      rgba(23, 23, 23, 0.03) 90%,
      rgba(226, 226, 226, 0.03) 90%,
      rgba(226, 226, 226, 0.03) 100%
    ),
    linear-gradient(
      45deg,
      rgba(52, 52, 52, 0.03) 0%,
      rgba(52, 52, 52, 0.03) 31%,
      rgba(246, 246, 246, 0.03) 31%,
      rgba(246, 246, 246, 0.03) 63%,
      rgba(188, 188, 188, 0.03) 63%,
      rgba(188, 188, 188, 0.03) 71%,
      rgba(15, 15, 15, 0.03) 71%,
      rgba(15, 15, 15, 0.03) 87%,
      rgba(127, 127, 127, 0.03) 87%,
      rgba(127, 127, 127, 0.03) 93%,
      rgba(234, 234, 234, 0.03) 93%,
      rgba(234, 234, 234, 0.03) 100%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);

  .container {
    max-width: 900px;
    margin: auto;
  }

  .header {
    .title {
      font-family: $font-family-1;
      font-size: 2.4rem;
    }
    .img-header {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      padding-bottom: 2rem;
      img {
        height: 160px;
        width: auto;
        max-width: 100%;
        object-fit: scale-down;
      }
    }
  }
}

.anmeldung2 {
  $width_break: 510px;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;

  min-height: 800px;
  .z-fragen-container {
    position: absolute;
    left: 50%;
    top: 0;
    padding: 2rem 1rem;
    border: 2px solid $primary-color;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    &.active {
      opacity: 1;
    }

    @media (max-width: $width_break) {
      padding: 1rem 0px;
    }
  }
  .content-container {
    min-height: 240px;

    width: 100%;

    padding: 0rem 12px;
  }

  small {
    color: $red-color;
    transition: all 0.3s ease-in;
    opacity: 0;
    display: block;
    margin: 0;
    padding-bottom: 0.2rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    &.error {
      opacity: 1;
    }
  }

  .slide-title {
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    @media (max-width: $width_break) {
      font-size: 1.3rem;
      padding: 0 12px;
      text-align: center;
    }
  }

  .img-slide {
    .row {
      max-width: 850px;
      margin: auto;
    }
    .one-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      cursor: pointer;
    }
    .img-container {
      overflow: hidden;
    }
    img {
      height: 200px;
      width: 200px;
      object-fit: cover;
      transition: $transition;

      &:hover {
        transform: scale(1.025);
      }
      @media (max-width: $width_break) {
        display: none;
      }
    }
    i {
      display: none;
      @media (max-width: $width_break) {
        display: block !important;

        text-align: center;
        width: 120px;
        font-size: 2.5rem;

        margin: 1rem auto;
      }
    }
    .img-text {
      text-align: center;
      font-size: 1.2rem;

      padding: 4px;
      padding-bottom: 0.5rem;
      max-width: 200px;
      @media (max-width: $width_break) {
        font-size: 1rem;
      }
    }
    .themeImages {
      transition: $transition;
      &.choosen {
        transition: $transition;
        padding: 0.5rem;
        border: $primary-color 4px solid;
        @media (max-width: $width_break) {
          padding: 0.25rem;
        }
      }
      &:hover {
        color: $primary-color;
      }
      margin-bottom: 0.75rem;
    }
  }

  .input-container {
    &.error {
      input {
        border: 0.5px solid $red-color;
      }
      small {
        opacity: 1;
      }
    }
  }
  .slide-1,
  .slide-2 {
    input {
      width: 100%;
    }
    p {
      font-size: 1.1rem;
      padding-bottom: 0.5rem;
    }
  }

  .slide-1 {
    .content-container {
      max-width: 600px;
    }
    #dropFrage2 {
      width: 280px;
      max-width: 100%;
      ul {
        width: 280px;
        max-width: 100%;
      }
    }
  }

  .slide-2 {
    .content-container {
      max-width: 800px;
    }
  }

  .slide-3 {
    p {
      font-size: 1.1rem;
      padding-bottom: 0.5rem;
    }
    textarea {
      width: 100%;
      height: 200px;
    }
    .content-container {
      max-width: 800px;
    }
  }

  // next side btns
  .z-option-btns {
    padding: 1rem 0rem 0.5rem 0rem;
    align-self: flex-end;
    button:nth-child(3) {
      margin: 0rem 2rem;
    }
    button.transparent {
      color: $primary-color;
    }
    @media (max-width: $width_break) {
      align-self: center;
    }
  }

  .option-text-area-container {
    width: 100%;
  }

  .law-container {
    display: flex;
    font-size: 0.8rem;
    max-width: 600px;
    a {
      color: $third-color;
      font-size: 0.8rem;
    }
    .law-text {
      padding-left: 0.5rem;
    }
    small {
      display: block;
      opacity: 0;
      font-size: 1rem;
      font-weight: 600;
      text-align: start;
      transition: all 0.25s ease-out;
    }
    &.error small {
      opacity: 1;
      color: $red-color;
    }
  }
}
