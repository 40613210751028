.social_media {
  padding-bottom: 3rem;
  .title-line {
    position: relative;
    padding-bottom: 2rem;
    z-index: 100;

    h3 {
      position: relative;
      overflow: hidden;
      text-align: center;
      font-size: 2.7rem;
      padding-top: 5rem;
      font-family: $font-family-1;
      span {
        position: absolute;
        transform: translate(10px, -45px);
        z-index: 100;
      }
    }
    h3:before,
    h3:after {
      background-color: $mittel-color;
      content: "";
      display: inline-block;
      height: 16px;
      position: relative;
      //   vertical-align: middle;
      width: 50%;
    }
    h3:before {
      right: 0.5em;
      margin-left: -50%;
    }
    h3:after {
      left: 0.5em;
      margin-right: -20%;
      width: 78%;
    }
    @media (max-width: 575.98px) {
      h3:before {
        right: 0.5em;
        margin-left: -50%;
      }
      h3:after {
        left: 0.5em;
        margin-right: -80%;
        width: 78%;
      }
    }
  }
  .main-logo-container {
    text-align: center;
    padding-bottom: 2rem;
    img {
      height: 200px;
      width: auto;
      @media (max-width: 575.98px) {
        height: 150px;
      }
    }
  }
  .z-container {
    height: 80px;
    width: 100%;
    max-width: 400px;
    // box-shadow: -7px -7px 17px rgba(255, 255, 255, 0.6),
    //   7px 7px 17px rgba(70, 70, 70, 0.15);
    // border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    a {
      height: 60px;
      width: 60px;
      box-shadow: -7px -7px 17px rgba(255, 255, 255, 0.6),
        7px 7px 17px rgba(70, 70, 70, 0.15);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 30px;
      transition: all 0.25 ease-out;
      &:hover {
        box-shadow: inset -7px -7px 17px rgba(255, 255, 255, 0.6),
          inset 7px 7px 17px rgba(70, 70, 70, 0.15);
      }
    }
  }

  .instagram-img {
    height: 32px;
    width: auto;
  }

  .facebook-img {
    height: 40px;
    width: auto;
  }
}
