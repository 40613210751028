$primary-color: #65b700;
$mittel-color: #b3d58a;
$hell-color: #d9faaf;
$gruen-color: #3e7100;
$secondary-color: #f8f9fa;
$third-color: darken($primary-color, 10%); // #2f6bcc;
$fourth-color: #2f6bcc;
$header-color-2: #fffce9;
$footer-color: #445b80;
$img-background: #1b3333;

$button-color: $primary-color;
$border-color: lighten(#333, 60%);

$test-color: #e15609;

$dark-color: #0b1826;
$gray-color: #999;
$light-color: #f4f4f4;
$red-color: #dc3545;
$blue-color: #1a73e8;
$green-color: #28a745;
$orange-color: #ffa500;

$gray-color-1: #fbfbfb;
$gray-color-2: #ececec;

$primary-overlay: lighten($primary-color, 20%);

//Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Racing+Sans+One&display=swap");
/* Roboto regular */

$transition: all 0.25s ease-out;
$font-family: "Poppins", Arial, Helvetica, sans-serif;
$font-family-1: "Racing Sans One", Arial, Helvetica, sans-serif;
$main-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
  0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
