.faq-simple {
  background-image: linear-gradient(
      22.5deg,
      rgba(242, 242, 242, 0.03) 0%,
      rgba(242, 242, 242, 0.03) 16%,
      rgba(81, 81, 81, 0.03) 16%,
      rgba(81, 81, 81, 0.03) 26%,
      rgba(99, 99, 99, 0.03) 26%,
      rgba(99, 99, 99, 0.03) 73%,
      rgba(43, 43, 43, 0.03) 73%,
      rgba(43, 43, 43, 0.03) 84%,
      rgba(213, 213, 213, 0.03) 84%,
      rgba(213, 213, 213, 0.03) 85%,
      rgba(125, 125, 125, 0.03) 85%,
      rgba(125, 125, 125, 0.03) 100%
    ),
    linear-gradient(
      22.5deg,
      rgba(25, 25, 25, 0.03) 0%,
      rgba(25, 25, 25, 0.03) 54%,
      rgba(144, 144, 144, 0.03) 54%,
      rgba(144, 144, 144, 0.03) 60%,
      rgba(204, 204, 204, 0.03) 60%,
      rgba(204, 204, 204, 0.03) 76%,
      rgba(37, 37, 37, 0.03) 76%,
      rgba(37, 37, 37, 0.03) 78%,
      rgba(115, 115, 115, 0.03) 78%,
      rgba(115, 115, 115, 0.03) 91%,
      rgba(63, 63, 63, 0.03) 91%,
      rgba(63, 63, 63, 0.03) 100%
    ),
    linear-gradient(
      157.5deg,
      rgba(71, 71, 71, 0.03) 0%,
      rgba(71, 71, 71, 0.03) 6%,
      rgba(75, 75, 75, 0.03) 6%,
      rgba(75, 75, 75, 0.03) 15%,
      rgba(131, 131, 131, 0.03) 15%,
      rgba(131, 131, 131, 0.03) 18%,
      rgba(110, 110, 110, 0.03) 18%,
      rgba(110, 110, 110, 0.03) 37%,
      rgba(215, 215, 215, 0.03) 37%,
      rgba(215, 215, 215, 0.03) 62%,
      rgba(5, 5, 5, 0.03) 62%,
      rgba(5, 5, 5, 0.03) 100%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);
  padding-top: 3rem;
  padding-bottom: 8rem;

  min-height: 100vh;
  margin-top: -8px;
  hr {
    width: 80px;
    color: $primary-color;
    height: 3px;
    background-color: $primary-color;
    margin-right: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }
  h2,
  h1 {
    color: $primary-color;
    font-size: 2rem;
  }
  .title-container {
    background-color: $gruen-color;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0.5rem 12px;
  }
  .faq-content-container {
    background: #fff;
    padding: 0.75rem 12px;
    margin-bottom: 3rem;
  }
  .now-contact-right {
    padding: 2rem 12px;
    background-color: #fff;
    border-radius: 4px;
    border: $green-color 4px dashed;
    font-size: 1.2rem;
    .col-2 {
      margin-right: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      img {
        height: 100px;
        width: auto;
        max-width: auto;
        object-fit: scale-down;
      }
    }
    .sub-title-2 {
      font-weight: 600;
    }
  }

  .left-col {
    .content {
      background-color: $primary-color;
      color: #fff;
      padding: 1.2rem 12px;
      border-radius: 4px;
      box-shadow: $main-box-shadow;
      .title {
        font-family: $font-family-1;
        font-size: 1.6rem;
      }

      li {
        padding-bottom: 0.4rem;
        font-size: 1.1rem;
      }
    }
    .now-contact {
      padding: 1.2rem 12px;
      border-radius: 4px;
      border: $gruen-color 4px dashed;
    }
    @media (max-width: 991.98px) {
      display: none;
    }
  }
}
