.kontakt2 {
  .map-container {
    padding: 0px 0px;
    margin: 0;
    margin-top: -8px;
    @media (max-width: 400.98px) {
      padding: 4px 2px;
    }
    iframe {
      width: 100%;
      height: 100%;
      padding: 0;
      object-fit: cover;
      min-height: 400px;
      border: none;
      @media (max-width: 400.98px) {
        min-height: 350px;
      }
    }
  }
  .main-part {
    margin-top: -8px;
    padding-top: 4rem;
    padding-bottom: 7rem;

    background-image: linear-gradient(
        45deg,
        transparent 0%,
        transparent 55%,
        rgba(64, 64, 64, 0.04) 55%,
        rgba(64, 64, 64, 0.04) 76%,
        transparent 76%,
        transparent 100%
      ),
      linear-gradient(
        135deg,
        transparent 0%,
        transparent 14%,
        rgba(64, 64, 64, 0.04) 14%,
        rgba(64, 64, 64, 0.04) 41%,
        transparent 41%,
        transparent 100%
      ),
      linear-gradient(
        45deg,
        transparent 0%,
        transparent 2%,
        rgba(64, 64, 64, 0.04) 2%,
        rgba(64, 64, 64, 0.04) 18%,
        transparent 18%,
        transparent 100%
      ),
      linear-gradient(
        135deg,
        transparent 0%,
        transparent 61%,
        rgba(64, 64, 64, 0.04) 61%,
        rgba(64, 64, 64, 0.04) 71%,
        transparent 71%,
        transparent 100%
      ),
      linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
    .main-content {
      max-width: 1400px;
      margin: auto;
    }
  }
  .kontakt-container {
    .content-container {
      background-color: #fff;
      padding: 2rem 1rem;
      border-radius: 12px;
      box-shadow: $main-box-shadow;
      margin-bottom: 2rem;
      @media (max-width: 575.98px) {
        padding: 2rem 8px;
      }
    }
    .title {
      font-size: 2.2rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      color: $primary-color;
    }
    .desc {
      margin-bottom: 0rem;
      font-size: 1.2rem;
      padding-bottom: 2rem;
    }

    .input-container {
      padding-bottom: 0.8rem;
      p {
        padding-bottom: 0.2rem;
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
    .law-container {
      display: flex;
      padding: 1rem 0rem;
      font-size: 0.75rem;
      a {
        font-size: 0.75rem;
      }
    }

    textarea {
      height: 200px;
    }
    .btn-container {
      text-align: center;
      button {
        text-transform: uppercase;
      }
    }
    .birthday-input {
      max-width: 120px;
    }
    small {
      display: none;
      color: $red-color;
      font-weight: 600;
      font-size: 1rem;
      transition: all 0.25 ease-out;
      &.error {
        display: block;
      }
    }
  }
  .text-seite {
    .content {
      background-color: #fff;

      padding: 2rem 1rem;
      border-radius: 12px;
      box-shadow: $main-box-shadow;

      @media (max-width: 575.98px) {
        padding: 2rem 8px;
      }
    }
    ul {
      font-size: 0.9rem;
      color: $gray-color;
      .z-bold {
        color: #000000;
      }
    }
    .sub-title {
      padding-top: 0.6rem;
      color: $primary-color;
      font-weight: 600;
      font-size: 1.3rem;
    }
    hr {
      width: 60px;
      color: $primary-color;
      height: 2px;
      background-color: $primary-color;
      margin-right: auto;
      border: none;
      margin-top: 0.2rem;
      margin-bottom: 1rem;
    }
  }
}
