.eine-klasse {
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 4rem;
  position: relative;
  &:before {
    content: "";
    position: absolute;

    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin-top: -3rem;
    opacity: 0.1;
    background: radial-gradient(
        circle,
        transparent 20%,
        #f8f9fa 20%,
        #f8f9fa 80%,
        transparent 80%,
        transparent
      ),
      radial-gradient(
          circle,
          transparent 20%,
          #f8f9fa 20%,
          #f8f9fa 80%,
          transparent 80%,
          transparent
        )
        10px 10px,
      linear-gradient(#65b700 0.8px, transparent 0.8px) 0 -0.4px,
      linear-gradient(90deg, #65b700 0.8px, #f8f9fa 0.8px) -0.4px 0;
    background-size: 20px 20px, 20px 20px, 10px 10px, 10px 10px;
  }
  .img-col {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 100%;
      height: 500px;
      max-width: 400px;

      object-fit: cover;
      @media (max-width: 767.98px) {
        height: 300px;
        padding-bottom: 3rem;
      }
    }
  }
  .title {
    font-family: $font-family-1;
    font-size: 2.4rem;
  }
  ul {
    li {
      padding-bottom: 1rem;
      @media (max-width: 575.98px) {
        padding-bottom: 1.5rem;
      }
    }
    i {
      font-size: 0.5rem;
      transform: translateY(-2px);
      color: $primary-color;
    }
  }
  @media (max-width: 575.98px) {
    font-size: 1.2rem;
  }
}
