.one-mockup {
  padding: 2rem 0rem;
  .content-col {
    background-color: $mittel-color;
  }
  .content {
    padding: 2rem 12px;
    max-width: 450px;
    margin: auto;
  }
  .title {
    font-family: $font-family-1;
    font-size: 4rem;
    color: $gruen-color;
  }
  .img-col {
    // background-color: $mittel-color;

    margin: 0;
    padding: 0;
    max-height: 550px;
    background-color: #f2f2f2;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      margin: 0;
      padding: 0;
    }
  }
}
