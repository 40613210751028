.z-nav {
  display: flex;
  z-index: 100;
  justify-content: space-between;
  height: 90px;
  max-width: 1400px;
  margin: auto;
  padding: 0rem 12px;
  width: 100%;

  color: $primary-color;
  // margin-bottom: 4px;
  position: relative;

  .z-logo-container {
    display: flex;
    padding-top: 0.4rem;

    img {
      height: 100px;
      width: auto;
      cursor: pointer;

      @media (max-width: 767.98px) {
        height: 75px;
      }
    }
  }
  .z-nav-pages {
    display: flex;
    padding: 0rem 2rem;
    align-items: center;
    .z-nav-page {
      padding-right: 2rem;
      a {
        color: $dark-color;
        font-size: 1.2rem;
        font-weight: 600;
        transition: $transition;
        &:hover {
          color: $gruen-color;
        }
      }
    }
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    cursor: pointer;
    div {
      width: 25px;
      height: 3px;
      background-color: $dark-color;
      margin: 2.5px;
      transition: $transition;
    }
  }
  .z-toggle {
    div {
      background-color: $gruen-color !important;
    }
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }

  .instagram-part {
    a {
      height: 60px;
      width: 60px;
      // box-shadow: -7px -7px 17px rgba(255, 255, 255, 0.6),
      //   7px 7px 17px rgba(70, 70, 70, 0.15);
      // border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 30px;
      transition: all 0.25 ease-out;
      // &:hover {
      //   box-shadow: inset -7px -7px 17px rgba(255, 255, 255, 0.6),
      //     inset 7px 7px 17px rgba(70, 70, 70, 0.15);
      // }
    }
  }

  .instagram-img {
    height: 32px;
    width: auto;
  }
  @media (max-width: 767.98px) {
    .z-nav-pages {
      background: #f2f2f2;
      box-shadow: 0 5px 5px -5px lighten(#333, 50%);
      display: flex;
      position: absolute;
      right: 0px;
      top: 0px;

      min-width: 300px;
      max-width: 800px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      z-index: 10;
      opacity: 0.9;
      padding-top: 0.9rem;
    }
    .z-nav-page {
      opacity: 0;
      padding: 1.2rem 0rem;
      padding-right: 0rem !important;
    }
    .z-burger {
      display: flex;
      z-index: 100;
    }
  }
}
@media (max-width: 767.98px) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
}

@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
