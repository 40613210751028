.law {
  padding-bottom: 8rem;
  padding-top: 4rem;
  background-image: radial-gradient(
      circle at 13% 47%,
      rgba(140, 140, 140, 0.03) 0%,
      rgba(140, 140, 140, 0.03) 25%,
      transparent 25%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 63%,
      rgba(143, 143, 143, 0.03) 0%,
      rgba(143, 143, 143, 0.03) 16%,
      transparent 16%,
      transparent 100%
    ),
    radial-gradient(
      circle at 81% 56%,
      rgba(65, 65, 65, 0.03) 0%,
      rgba(65, 65, 65, 0.03) 12%,
      transparent 12%,
      transparent 100%
    ),
    radial-gradient(
      circle at 26% 48%,
      rgba(60, 60, 60, 0.03) 0%,
      rgba(60, 60, 60, 0.03) 6%,
      transparent 6%,
      transparent 100%
    ),
    radial-gradient(
      circle at 97% 17%,
      rgba(150, 150, 150, 0.03) 0%,
      rgba(150, 150, 150, 0.03) 56%,
      transparent 56%,
      transparent 100%
    ),
    radial-gradient(
      circle at 50% 100%,
      rgba(25, 25, 25, 0.03) 0%,
      rgba(25, 25, 25, 0.03) 36%,
      transparent 36%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 52%,
      rgba(69, 69, 69, 0.03) 0%,
      rgba(69, 69, 69, 0.03) 6%,
      transparent 6%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  h1 {
    text-align: center;
    color: $primary-color;
    font-size: 3rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }
  h2 {
    padding-top: 1rem;
  }

  p {
    margin: 0.2rem 0rem;
    padding: 0rem 0rem;
  }
  h3 {
    padding-top: 0.6rem;
    color: $primary-color;
  }
  a {
    text-decoration: underline;
  }

  hr {
    width: 60px;
    color: $primary-color;
    height: 2px;
    background-color: $primary-color;
    margin-right: auto;
    border: none;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
  }
}
