.extra-info {
  .content {
    background-color: #f2f2f2;
    padding: 1rem 16px;
    border-radius: 4px;
  }
  .title {
    font-size: 2rem;
    font-family: $font-family-1;
  }
}
