.unsere-angebote {
  padding-top: 5rem;
  padding-bottom: 7rem;
  .title-line {
    position: relative;
    padding-bottom: 5rem;
    z-index: 100;

    h3 {
      position: relative;
      overflow: hidden;
      text-align: center;
      font-size: 2.7rem;
      padding-top: 5rem;
      font-family: $font-family-1;
      span {
        position: absolute;
        transform: translate(10px, -45px);
        z-index: 100;
      }
    }
    h3:before,
    h3:after {
      background-color: $mittel-color;
      content: "";
      display: inline-block;
      height: 16px;
      position: relative;
      //   vertical-align: middle;
      width: 50%;
    }
    h3:before {
      right: 0.5em;
      margin-left: -50%;
    }
    h3:after {
      left: 0.5em;
      margin-right: -20%;
      width: 78%;
    }
    @media (max-width: 575.98px) {
      h3:before {
        right: 0.5em;
        margin-left: -50%;
      }
      h3:after {
        left: 0.5em;
        margin-right: -80%;
        width: 78%;
      }
    }
  }
  .container-extra {
    max-width: 750px;
    margin: auto;
  }
  .bubble-1-container {
    max-width: 350px;
    @media (max-width: 767.98px) {
      margin: auto;
      padding-bottom: 2rem;
    }
  }
  .bubble-2-container {
    max-width: 350px;
    margin-left: auto;
    margin-right: 0;
    margin-top: -6rem;

    @media (max-width: 767.98px) {
      margin: auto;
      padding-bottom: 2rem;
    }
  }
  .bubble-3-container {
    max-width: 350px;
    margin-top: -6rem;
    @media (max-width: 767.98px) {
      margin: auto;
      padding-bottom: 2rem;
    }
  }
  .speech-bubble {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 300px;
    color: #fff;
    border-radius: 100%;
    transition: all 0.5s ease;
    cursor: pointer;
    img {
      height: 60px;
      width: 60px;
      object-fit: scale-down;
    }
    p {
      font-size: 1.8rem;
      font-weight: 600;
      max-width: 200px;
      text-align: center;
    }
  }
  .speech-bubble-1 {
    background-color: $gruen-color;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 240px;
      right: -9px;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid transparent;
      border-left: 30px solid $gruen-color;
      transform: rotate(40deg);
    }
    &:hover {
      transform: rotate(-90deg) scale(1.05);
    }
  }
  .speech-bubble-2 {
    background-color: $primary-color;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: 20px;
      right: 225px;
      border-bottom: 20px solid transparent;
      border-top: 30px solid $primary-color;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      border-left: 30px solid $primary-color;
      transform: rotate(16deg);
    }
    &:hover {
      transform: rotate(90deg) scale(1.05);
    }
  }
  .speech-bubble-3 {
    background-color: $mittel-color;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 240px;
      right: -9px;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid transparent;
      border-left: 30px solid $mittel-color;
      transform: rotate(40deg);
    }
    &:hover {
      transform: rotate(-90deg) scale(1.05);
    }
  }
}
